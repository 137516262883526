.App {
  text-align: center;
  background-color: #282c34;
  height: 100vh;  
  color: white;
  display: flex;
  flex-direction: column;    
  user-select: none;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

body {
  background-color: #282c34;
}

p {
  font-size: calc(2px + 2vmin);
  margin: 0px;
}

span {
  font-size: calc(1.5px + 2vmin);
  margin: 0px;
}

input {
  width: 100%;
  height: 15px;
  margin: 0px;  
}

.button-bg {
  padding-top: 15px;
  padding-bottom: 2px;
  background-color: rgba(0,0,0,0.3);
  margin: 3px;
  border-radius: 10px;
}

.button-bg-text {
  padding-top: 2px;
  color: rgba(255,255,255,0.9)
}

.smallinput {
  width: 40px;  
}

.searchtitle {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.press-button:hover {
  opacity: 0.85
}

.element-button {
  cursor: pointer;
}

.element-button:hover {
  opacity: 0.83;
}

.small-text {
  font-size: calc(1px + 1.2vmin);
}

.dark {
  font-size: calc(1px + 1vmin);
  opacity: 0.8;
}

.searchrow {
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.searchtitletext {
  font-size: calc(2px + 1.5vmin);
}

.searchcontent {
  flex: 2.6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-container {  
  height: 92%;
  width: 106%;  
}

.card-img {
  height: 110%;
  width: 100%;
  margin-bottom: -14%;
  object-fit: contain;
  cursor: pointer;  
}

.cardpool-img {
  height: 120%;
  width: 102%;
  margin-bottom: -19%;
}

.card-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-bottom: 40vh;  
  
}

.card-list-scroll {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1px;   
  padding-right: 10px;   
  background-color: rgba(0,0,0,0.3);
  border: 3px solid rgba(40,40,40,1);  
}

.deckcard-bg {
  background-color: rgba(0,0,0,0.2);
  height: 100%;
  width: 100%;
}

.deck-list {    
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.side-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.deck-list-bg {      
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.card-container-bg {
  background-color: rgba(0,255,155,0.5);
  margin: 1px;
  height: 95px;
  width: 60px;
}

.empty {
  cursor: default;
}

.card-highlight-container {
  
}

.card-highlight {
  height: 100%;
  width: 107%;
  margin-top: -2%;
  margin-left: -3%;
  object-fit: contain;
}

.card-img:hover {
  opacity: 0.85;
}


/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: rgba(255,255,255,0);
  width: 8px;
  padding-right: 1px;
  padding-top: 2px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: rgba(255,255,255,0);
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #2f333b;
  border-radius: 20px;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}

/* total width */
.card-list-scroll::-webkit-scrollbar {
  background-color: rgba(255,255,255,0);
  width: 8px;
  margin-right: 4px;
}

/* background of the scrollbar except button or resizer */
.card-list-scroll::-webkit-scrollbar-track {
  background-color: rgba(255,255,255,0);
  margin-top: 2px;  
}

/* scrollbar itself */
.card-list-scroll::-webkit-scrollbar-thumb {
  background-color: #363a41;
  border-radius: 20px;  
  border: 1px solid #26292f;
}

/* set button(top and bottom of the scrollbar) */
.card-list-scroll::-webkit-scrollbar-button {
  display:none;
}